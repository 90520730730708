import styled from "styled-components";

import { calcColumnWidth } from "../../utils/layout";

import LeftP from "../../components/LeftPane";
import Button from "../../components/Button";
import SocialBtn from "../../components/socials/button";
import BodyFooterLayout from "../../components/BodyFooterLayout";
import Title from "../../components/Title";
import Text from "../../components/Text";
import SplitLayout from "../../components/SplitLayout";

export const LeftPane = styled(LeftP)`
  padding: 243px 0 0 0;
  @media only screen and (max-width: 1280px) {
    display: none;
  }
`;

export const RightPaneLayout = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LeftPaneTitle = styled.div`
  padding: 0 0 86px 0;
  text-align: center;
  padding-left: ${() => calcColumnWidth(2, 0, "start")};
  padding-right: ${() => calcColumnWidth(2, 0, "start")};

  img {
    width: 211px;
  }
  p {
    margin-bottom: 0;
  }

  @media only screen and (max-width: 1280px) {
    padding-left: ${() => calcColumnWidth(1, 0, "start")};
    padding-right: ${() => calcColumnWidth(1, 0, "start")};
  }
`;

export const LeaderFall = styled.p`
  margin-top: 40px;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
`;

export const GirlsWhoCode = styled.section`
  height: 100%;
  margin-right: 1px;
  padding: 60px 0;
  background: #fff;
  text-align: center;
  font-size: 15px;
  line-height: 24px;
  font-weight: bold;
`;

export const ReviewBtn = styled(Button)`
  background: #ffc9c6;
  color: #1a192b;
  &:hover {
    background-color: rgba(255, 201, 198, 0.5);
  }
`;

export const SocialButton = styled(SocialBtn)`
  position: relative;
  left: -12px;
`;

export const SocialButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 6px;
  max-width: 450px;
  @media only screen and (max-width: 400px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const ForgetPassword = styled.div`
  font-size: 13px;
  margin-bottom: 24px;
  @media only screen and (max-width: 1280px) {
    margin-bottom: 40px;
  }
`;

export const EmailText = styled(Text)`
  font-size: 15px;
  line-height: 33px;
  color: #98a0ab;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin-right: 16px;
  }
`;

export const BodyLayout = styled(BodyFooterLayout)`
  @media only screen and (max-width: 1280px) {
    padding: 0;
    .seperator {
      width: 100%;
      margin: 18px 0 0 0;
    }
    input#username,
    .username-layout,
    input#password,
    .password-layout {
      width: 100%;
      max-width: 100%;
    }
    button,
    .loading-wrapper {
      max-width: 100%;
      width: 100%;
    }
  }
`;

export const TitleWrapper = styled.div`
  .sm {
    display: block;
  }
  .lg {
    display: none;
  }
margin-top:115px;
  @media only screen and (min-width: 1200px) {
    .sm {
      display: none;
    }
    .lg {
      display: block;
    }
    .login-description {
      margin: 40px 0 24px 0;
    }
  }

  .login-description {
    margin-bottom: 18px;
  }

  ${Title} {
    max-width: max-content;
  }
`;

export const HiddenInput = styled.input`
  position: absolute;
  top: -20px;
  height: 0px;
  zindex: 0;
`;

export const LoginLayout = styled(SplitLayout)`
@media only screen and (max-width: 1280px) {
  display: flex;
  justify-content: center;
}

background:#fff;
@media only screen and (min-width: 1680px), (max-width: 940px) and (min-width: 600px){
  width:auto;
  max-width:none;
}

@media only screen and (max-width: 1280px) and (min-width: 940px){
.logo{
  position: absolute;
  left: 40px;
  top: 35px;
}
`;
